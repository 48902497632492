const tokens = {
  kcs: {
    symbol: 'KCS',
    projectLink: 'https://kcc.io/',
  },
  wkcs: {
    symbol: 'WKCS',
    address: {
      321: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521',
      322: '0x6551358EDC7fee9ADAB1E2E49560E68a12E82d9e',
    },
    decimals: 18,
    projectLink: '',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      321: '0x980a5AfEf3D17aD98635F6C5aebCBAedEd3c3430',
      322: '0xD6c7E27a598714c2226404Eb054e0c074C906Fc9',
    },
    decimals: 18,
    projectLink: '',
  },
  prntr: {
    symbol: 'PRNTR',
    address: {
      321: '0xd047764d8915e0c482a8dd5804830bb8ff5a5285',
      322: '',
    },
    decimals: 18,
    projectLink: 'https://paprprintr.finance/',
  },
  papr: {
    symbol: 'PAPR',
    address: {
      321: '0x9deb450638266f787e6e29d0fe811069f828cf56',
      322: '',
    },
    decimals: 18,
    projectLink: 'https://paprprintr.finance/',
  },
}

export default tokens
